import React from 'react'
import SEOTools from '../../../components/SEOTools'
import { graphql, Link } from 'gatsby'
import { Button, Col, Container, Jumbotron, Row } from 'react-bootstrap'
import { GatsbyImage, getImage, getSrc, StaticImage } from 'gatsby-plugin-image'
import useSmoothScrollTo from '../../../hooks/useSmoothScrollTo'
import {
    faCog,
    faCogs,
    faEnvelope,
    faGlobeEurope,
    faShieldAlt,
    faSmile,
    faStore,
    faUserLock,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ContactForm from '../../../components/Forms/ContactForm'
import Layout from '../../../components/Layout'

/**
 * @param data
 * @returns {JSX.Element}
 * @constructor
 */
const SiruPayPage = ({ data }) => {
    const heroBg = getImage(data.heroBg)
    const checkOutBg = getImage(data.checkOutBg)

    const handleScrollToContact = useSmoothScrollTo('contact', { offset: -45 })

    return (
        <Layout>
            <SEOTools
                title='SiruPay'
                description='Any phone can be a fast payment tool. With SiruPay, a new customer can move through checkout in 30 seconds, never having to key in payment details.'
                image={getSrc(data.heroBg.childImageSharp)}
            />

            <section className='dark-background-overlay position-relative'>
                <div className='text-white mb-0 d-flex align-items-center full-screen-jumbo'>
                    <Container>
                        <Row>
                            <Col lg={{ span: 6, offset: 1 }} md='8'>
                                <h1 className='name mb-4 text-uppercase'>SiruPay</h1>
                                <p className='message mb-5 pb-3'>
                                    Mobile payments <strong>made easy.</strong>
                                </p>
                                <Button
                                    variant='success'
                                    size='lg'
                                    className='px-5 rounded-0'
                                    onClick={handleScrollToContact}>
                                    Talk to sales
                                </Button>
                                <Link to='/products/sirupay/demo' className='btn btn-link btn-lg px-5 py-3'>
                                    See demo
                                </Link>
                            </Col>
                        </Row>
                    </Container>
                    <GatsbyImage
                        className='z-index-n1 position-absolute w-100 h-100'
                        alt=''
                        image={heroBg}
                        role='presentation'
                        placeholder='blurred'
                        loading='eager'
                    />
                </div>
            </section>

            <section>
                <Jumbotron fluid>
                    <Container>
                        <Row>
                            <Col lg={{ span: 10, offset: 1 }}>
                                <h2>Features</h2>
                                <Row className='mb-3'>
                                    <Col md='1' xs='2' className='pt-1 pl-4'>
                                        <FontAwesomeIcon icon={faShieldAlt} size='lg' />
                                    </Col>
                                    <Col md='5' xs='10' className='pl-0'>
                                        <p className='spaced-light mb-2'>100% Risk free</p>
                                        <p>Siru Mobile carries the risk for credit losses</p>
                                    </Col>

                                    <Col md='1' xs='2' className='pt-1 pl-4'>
                                        <FontAwesomeIcon icon={faUserLock} size='lg' />
                                    </Col>
                                    <Col md='5' xs='10' className='pl-0'>
                                        <p className='spaced-light mb-2'>Strong user authentication</p>
                                        <p>Option to integrate strong authentication to the payment flow.</p>
                                    </Col>
                                </Row>

                                <Row className='mb-3'>
                                    <Col md='1' xs='2' className='pt-1 pl-4'>
                                        <FontAwesomeIcon icon={faGlobeEurope} size='lg' />
                                    </Col>
                                    <Col md='5' xs='10' className='pl-0'>
                                        <p className='spaced-light mb-2'>Easy access to new markets</p>
                                        <p>
                                            Expand to new markets easily after integrating mobile payments in one
                                            country.
                                        </p>
                                    </Col>

                                    <Col md='1' xs='2' className='pt-1 pl-4'>
                                        <FontAwesomeIcon icon={faStore} size='lg' />
                                    </Col>
                                    <Col md='5' xs='10' className='pl-0'>
                                        <p className='spaced-light mb-2'>Merchant portal</p>
                                        <p>24/7 access to reports and transaction details.</p>
                                    </Col>
                                </Row>

                                <Row className='mb-3'>
                                    <Col md='1' xs='2' className='pt-1 pl-4'>
                                        <FontAwesomeIcon icon={faCog} size='lg' />
                                    </Col>
                                    <Col md='5' xs='10' className='pl-0'>
                                        <p className='spaced-light mb-2'>Automatic settlements</p>
                                        <p>Regular settlements according to chosen cycle.</p>
                                    </Col>

                                    <Col md='1' xs='2' className='pt-1 pl-4'>
                                        <FontAwesomeIcon icon={faSmile} size='lg' />
                                    </Col>
                                    <Col md='5' xs='10' className='pl-0'>
                                        <p className='spaced-light mb-2'>Safe and responsible</p>
                                        <p>Built-in risk management and user limitations.</p>
                                    </Col>
                                </Row>

                                <Row className='mb-3'>
                                    <Col md='1' xs='2' className='pt-1 pl-4'>
                                        <FontAwesomeIcon icon={faCogs} size='lg' />
                                    </Col>
                                    <Col md='5' xs='10' className='pl-0'>
                                        <p className='spaced-light mb-2'>Easy integration</p>
                                        <p>Integrate directly or utilize a ready network of partners.</p>
                                    </Col>

                                    <Col md='1' xs='2' className='pt-1 pl-4'>
                                        <FontAwesomeIcon icon={faEnvelope} size='lg' />
                                    </Col>
                                    <Col md='5' xs='10' className='pl-0'>
                                        <p className='spaced-light mb-2'>Subscription</p>
                                        <p>
                                            Supports subscription-based billing, charging the customer on a recurring
                                            basis.
                                        </p>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Container>
                </Jumbotron>
            </section>

            <section className='darker-background-overlay position-relative'>
                <div className='d-flex text-white text-center mb-0 pb-0'>
                    <Container>
                        <Row className='mt-4 mb-0 pb-0 d-flex justify-content-center'>
                            <Col md='10'>
                                <h2>Check-out in 30 seconds</h2>
                                <p className='spaced-light mb-5'>
                                    With Siru Mobiles payment solution, a new customer can move through checkout in 30
                                    seconds, never having to key in payment details or go through extra steps.
                                </p>
                                <StaticImage
                                    src='../../../images/products/sirupay/sirumobile_product_sirupay_phone.png'
                                    alt='logo'
                                    placeholder='blurred'
                                    width={350}
                                />
                            </Col>
                        </Row>
                    </Container>
                    <GatsbyImage
                        className='z-index-n1 position-absolute w-100 h-100'
                        alt=''
                        image={checkOutBg}
                        role='presentation'
                        placeholder='blurred'
                        loading='lazy'
                    />
                </div>
            </section>

            <section id='contact'>
                <Container className='py-5'>
                    <Row className='mt-4 mb-0 pb-0 d-flex justify-content-center'>
                        <Col md='10'>
                            <h2>Contact our sales team</h2>
                            <ContactForm name='sales-contact' />
                        </Col>
                    </Row>
                </Container>
            </section>
        </Layout>
    )
}

export const query = graphql`
    query {
        heroBg: file(relativePath: { eq: "products/sirupay/sirupay_hero.jpg" }) {
            childImageSharp {
                gatsbyImageData(width: 3840, placeholder: BLURRED, transformOptions: { cropFocus: CENTER })
            }
        }
        checkOutBg: file(relativePath: { eq: "products/sirupay/sirumobile_product_sirupay.jpg" }) {
            childImageSharp {
                gatsbyImageData(
                    width: 3840
                    height: 500
                    placeholder: BLURRED
                    quality: 80
                    transformOptions: { cropFocus: CENTER, fit: OUTSIDE }
                )
            }
        }
    }
`

export default SiruPayPage
